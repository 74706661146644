define('ember-cli-group-by/helpers/group-by-path', ['exports', 'ember-cli-group-by/utils/group-by'], function (exports, _groupBy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Helper.extend({
    /**
     * On parameter changes, trigger a re-group-by update.
     *
     * @private
     */
    paramsDidChanged: Ember.observer('array.[]', 'propertyPath', 'groupDefinition', function () {
      var _this = this;

      var array = Ember.get(this, 'array');
      var propertyPath = Ember.get(this, 'propertyPath');
      var groupDefinition = Ember.get(this, 'groupDefinition');

      function groupPropertyDidChange() {
        return (0, _groupBy.default)(array, propertyPath, groupDefinition);
      }

      if (Ember.isEmpty(propertyPath)) {
        Ember.defineProperty(this, 'content', null);
      } else {
        // Chain computed properties since @each only works one level deep.
        if (propertyPath.includes('.')) {
          propertyPath.split('.').forEach(function (path, index, paths) {
            if (index === 0) {
              Ember.defineProperty(_this, '_chain_' + index, Ember.computed.mapBy('array', path));
            } else if (index + 1 === paths.length) {
              Ember.defineProperty(_this, 'content', Ember.computed('_chain_' + (index - 1) + '.@each.{' + path + '}', groupPropertyDidChange));
            } else {
              Ember.defineProperty(_this, '_chain_' + index, Ember.computed.mapBy('_chain_' + (index - 1), path));
            }
          });
        } else {
          Ember.defineProperty(this, 'content', Ember.computed('array.@each.' + propertyPath, groupPropertyDidChange));
        }
      }

      Ember.run.once(this, this.recompute);
    }),

    /**
     * Force re-computation on content change.
     *
     * @private
     */
    contentDidChange: Ember.observer('content.[]', function () {
      this.recompute();
    }),

    /**
     * Groups the array by nested async properties.
     *
     * @param {Array} array
     * @param {String} propertyPath
     * @param {Function} groupDefinition
     * @return {Ember.Object} - The grouped object.
     */
    compute: function compute(_ref) {
      var _this2 = this;

      var _ref2 = _slicedToArray(_ref, 3),
          array = _ref2[0],
          propertyPath = _ref2[1],
          groupDefinition = _ref2[2];

      Ember.deprecate('First parameter should be an array followed by the property key.', typeof array !== 'string', {
        id: 'ember-cli-group-by.order-parameter',
        until: '0.0.6'
      });

      Ember.deprecate('The parameter missing has been removed. Use an action to set the default group.', typeof groupDefinition !== 'string', {
        id: 'ember-cli-group-by.remove-missing',
        until: '0.0.6'
      });

      Ember.set(this, 'array', array);
      Ember.set(this, 'propertyPath', propertyPath);
      Ember.set(this, 'groupDefinition', groupDefinition);

      Object.keys(this).forEach(function (property) {
        if (property.startsWith('_chain')) {
          Ember.get(_this2, property);
        }
      });

      return Ember.get(this, 'content');
    }
  });
});