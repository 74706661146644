define('ember-google-charts/utils/render-classic-chart', ['exports', 'ember', 'ember-google-charts/utils/visualization-names'], function (exports, _ember, _emberGoogleChartsUtilsVisualizationNames) {
  exports['default'] = renderClassicChart;
  var RSVP = _ember['default'].RSVP;

  function renderClassicChart(data, options) {
    var _this = this;

    return new RSVP.Promise(function (resolve, reject) {
      var visualization = window.google.visualization;

      var type = _this.get('type');
      var visualizationName = _emberGoogleChartsUtilsVisualizationNames['default'][type];
      var chart = new visualization[visualizationName](_this.get('element'));
      var dataTable = visualization.arrayToDataTable(data);

      visualization.events.addListener(chart, 'error', reject);

      /* For charts that aren't immediately ready, listen for the
      ready event */

      if (type === 'geo') {
        visualization.events.addListener(chart, 'ready', function () {
          resolve(chart);
        });
      }

      chart.draw(dataTable, options);

      if (type !== 'geo') {
        resolve(chart);
      }
    });
  }
});