define('ember-ux-sauce/components/uxs-tiles', ['exports', 'ember-ux-sauce/templates/components/uxs-tiles', 'ember-ux-sauce/mixins/testable', 'ember-bem-sauce/mixins/bem-component'], function (exports, _uxsTiles, _testable, _bemComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_testable.default, _bemComponent.default, {
    layout: _uxsTiles.default,
    // Attributes
    /**
      The base css class name 'uxs-tiles__group'
       @field base
      @type String
     */
    base: 'uxs-tiles__group',
    /**
      Set the size of child tiles
       UXS ships with three align options: small, medium & large
       @argument size
      @type     String
      @default  "medium"
      @public
     */
    size: 'medium',
    /**
      Set the style of the splash component.
       UXS ships with the following stock styles: primary, accent, warning, error, dark, grey, mid, light & white.
       You can customise your component by using any string here and adding your own css for the custom modifier e.g. _.uxs-splash--my-custom-style_
       @argument style
      @type     String
      @default  null
      @public
     */
    style: null,
    // Capacity
    capacity: Ember.computed('size', function () {
      switch (Ember.get(this, 'size')) {
        case 'small':
          return 5;
        case 'medium':
          return 3;
        case 'large':
          return 2;
      }
    }),
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['scroll', '*style']);
    },
    didInsertElement: function didInsertElement() {
      this.autoDetectScrollMode();
    },

    /**
     * Auto detect when scroll is needed
     **/
    autoDetectScrollMode: function autoDetectScrollMode() {
      // let theInnerHtml = this.element;
      var capacity = Ember.get(this, 'capacity'),
          selector = '#' + this.elementId + ' .uxs-tiles__item',
          numberOfTiles = document.querySelectorAll(selector).length;

      if (numberOfTiles > capacity) {
        Ember.set(this, 'scroll', true);
      }
    }
  });
});