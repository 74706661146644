define('ember-cli-group-by/macros/index', ['exports', 'ember-cli-group-by/macros/group-by-path'], function (exports, _groupByPath) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'groupByPath', {
    enumerable: true,
    get: function () {
      return _groupByPath.groupByPath;
    }
  });
});