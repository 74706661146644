define('ember-cli-group-by/utils/group-by', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = groupBy;


  var PromiseProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);

  /**
   * Groups the array by nested properties helper for computed property and helper.
   *
   * References: https://gist.github.com/Asherlc/cc438c9dc13912618b8b
   *
   * @method groupBy
   * @static
   * @param array {Array}
   * @param key {String}
   * @param definition {Function}
   * @public
   */
  function groupBy(array, key, definition) {
    var arrayPromise = Ember.RSVP.resolve(array);
    var groupPromise = arrayPromise.then(function (items) {
      var groups = Ember.Object.create({});

      items.map(function (item) {
        var groupName = key.split('.').reduce(function (previous, path) {
          var itemPromise = Ember.RSVP.resolve(previous);
          return itemPromise.then(function (item) {
            return Ember.isEmpty(item) ? undefined : Ember.get(item, path);
          });
        }, item);

        groupName.then(function (groupName) {
          var groupKey = definition ? definition(groupName) : groupName;
          var currentGroup = Ember.get(groups, '' + groupKey);

          if (!Ember.isArray(currentGroup)) {
            currentGroup = Ember.A();
            groups['' + groupKey] = currentGroup;
          }

          currentGroup.pushObject(item);
        });
      });

      return groups;
    });

    return PromiseProxy.create({
      promise: groupPromise
    });
  }
});