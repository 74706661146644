define('ember-google-charts/components/google-chart', ['exports', 'ember'], function (exports, _ember) {
  var $ = _ember['default'].$;
  var VERSION = _ember['default'].VERSION;
  var Component = _ember['default'].Component;
  var assert = _ember['default'].assert;
  var computed = _ember['default'].computed;
  var inject = _ember['default'].inject;
  var debounce = _ember['default'].run.debounce;
  var warn = _ember['default'].warn;

  var isUsingEmber2 = VERSION.match(/\b2\.\d+.\d+\b/g);

  exports['default'] = Component.extend({

    /* Services */

    googleCharts: inject.service(),

    /* Actions */

    chartDidRender: null,
    packagesDidLoad: null,

    /* Options */

    data: null,
    defaultOptions: {
      animation: {
        duration: 500,
        startup: false
      }
    },
    options: null,
    type: null,

    /* Properties */

    chart: null,
    classNameBindings: ['className'],
    classNames: ['google-chart'],
    responsiveResize: true,

    className: computed('type', function () {
      return this.get('type') + '-chart';
    }),

    /**
    The default options object with any properties specified
    in the options property overriding specific default options.
     @property mergedOptions
    @public
    */

    mergedOptions: computed('defaultOptions', 'options', function () {
      var defaultOptions = this.get('defaultOptions');
      var options = this.get('options');

      return $.extend({}, defaultOptions, options);
    }),

    /* Lifecycle hooks */

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      this.setupDependencies();

      /* If the Ember version is less than 2.0.0... */

      if (!isUsingEmber2) {
        this.addObserver('data', this, this._rerenderChart);
        this.addObserver('mergedOptions', this, this._rerenderChart);
      }

      if (this.get('responsiveResize')) {
        $(window).on('resize.' + this.get('elementId'), function () {
          return debounce(_this, '_handleResize', 200);
        });
      }
    },

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this._rerenderChart();
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this._teardownChart();
    },

    /* Methods */

    /**
    The method that components that extend this component should
    overwrite.
     @method renderChart
    @public
    */

    renderChart: function renderChart() {
      assert('You have created a chart type without a renderChart() method');
    },

    setupDependencies: function setupDependencies() {
      var _this2 = this;

      var type = this.get('type');
      var options = { id: 'setup-dependencies' };

      warn('You did not specify a chart type', type, options);

      this.get('googleCharts').loadPackages().then(function () {
        _this2.sendAction('packagesDidLoad');
        _this2._renderChart();
      });
    },

    _rerenderChart: function _rerenderChart() {
      if (this.get('chart') && this.get('data')) {
        this._renderChart();
      }
    },

    _handleResize: function _handleResize() {
      this.$().css({
        display: 'flex'
      });

      /* Classic charts have an extra parent div */

      var chartContainer = this.$().children().children().css('position') === 'absolute' ? this.$().children() : this.$().children().children();

      chartContainer.css({
        width: '',
        flex: 'auto'
      });

      this._rerenderChart();
    },

    _renderChart: function _renderChart() {
      var _this3 = this;

      var data = this.get('data');
      var mergedOptions = this.get('mergedOptions');

      this.renderChart(data, mergedOptions).then(function (chart) {
        _this3.set('chart', chart);
        _this3.sendAction('chartDidRender', chart);
      });
    },

    _teardownChart: function _teardownChart() {
      var chart = this.get('chart');

      if (chart) {
        window.google.visualization.events.removeAllListeners(chart);
        chart.clearChart();
      }

      if (!isUsingEmber2) {
        this.removeObserver('data', this, this._rerenderChart);
        this.removeObserver('mergedOptions', this, this._rerenderChart);
      }

      $(window).off('resize.' + this.get('elementId'));
    }

  });
});