define('ember-cli-group-by/macros/group-by-path', ['exports', 'ember-cli-group-by/utils/group-by'], function (exports, _groupBy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.groupByPath = groupByPath;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  /**
   * Groups the array by nested async properties.
   *
   * @method groupByPath
   * @static
   * @param {String} dependentKey
   * @param {String} propertyPath
   * @param {Function} groupDefinition
   * @return {ComputedProperty} The grouped object.
   * @public
   */
  function groupByPath(dependentKey, propertyPath, groupDefinition) {
    var cp = new Ember.ComputedProperty(function (key) {
      var _this = this;

      // Add/remove property observers as required.
      var activeObserversMap = cp._activeObserverMap || (cp._activeObserverMap = new WeakMap());
      var activeObservers = activeObserversMap.get(this);

      if (activeObservers !== undefined) {
        activeObservers.forEach(function (args) {
          return Ember.removeObserver.apply(undefined, _toConsumableArray(args));
        });
      }

      function groupPropertyDidChange() {
        this.notifyPropertyChange(key);
      }

      // Unique computed property name so they do not overwrite each other.
      var chain = '_' + Ember.String.camelize('chain_' + dependentKey.replace('.', ' ') + '_' + propertyPath.replace('.', ' '));

      // Chain computed properties since @each only works one level deep.
      propertyPath.split('.').forEach(function (path, index, paths) {
        if (index === 0) {
          Ember.defineProperty(_this, '' + chain + index, Ember.computed.mapBy(dependentKey, path));
          Ember.get(_this, '' + chain + index);
        } else if (index + 1 === paths.length) {
          Ember.addObserver(_this, '' + chain + (index - 1) + '.@each.' + path, groupPropertyDidChange);
          activeObserversMap.set(_this, [[_this, '' + chain + (index - 1) + '.@each.' + path, groupPropertyDidChange]]);
        } else {
          Ember.defineProperty(_this, '' + chain + index, Ember.computed.mapBy('' + chain + (index - 1), path));
          Ember.get(_this, '' + chain + index);
        }
      });

      var itemsKeyIsAtThis = dependentKey === '@this';
      var items = itemsKeyIsAtThis ? this : Ember.get(this, dependentKey);

      if (!Ember.isArray(items)) {
        return Ember.Object.create({});
      }

      return (0, _groupBy.default)(items, propertyPath, groupDefinition);
    }, { dependentKeys: [dependentKey + '.[]'], readOnly: true });

    cp._activeObserverMap = undefined;

    return cp;
  }
});