define('ember-flatpickr/components/ember-flatpickr', ['exports', 'ember-flatpickr/mixins/flatpickr'], function (exports, _flatpickr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_flatpickr.default, {
    tagName: 'input',
    type: "text",
    attributeBindings: ["placeholder", "type"],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.set('field', this.element);
      this.setupFlatpickr();
    }
  });
});